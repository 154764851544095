.settings__topSection {
  display: flex;
}
.settings-tables {
  display: flex;
}
.add-useragent-form {
  display: flex;
  padding: 24px;
  align-items: flex-end;
}

.add-useragent-button {
  flex: none !important;
  margin-left: 12px !important;
}

.useragents-table {
  .ant-table-tbody > tr > td {
    padding: 8px 4px !important;
    font-size: 13px;
    line-height: 18px;
  }
}

.horizontal-form-item .ant-form-item-label {
  display: block;
}

.settings-form.ant-form-inline {
  .ant-form-item {
    display: flex;
    flex-direction: column;
    width: 150px;
    max-width: 150px;
  }

  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-control-wrapper {
    text-align: right;

    button {
      position: relative;
      top: 18px;
    }
  }
}

.excludedDomainList {
  display: flex;
  flex-direction: column;
  padding-left: 48px;
}

.excludedDomainList__formWrapper {
  display: flex;
  align-items: baseline;
}

.settingsHeading {
  font-size: 16px;
  font-weight: 500;
}

.excludedDomainList__chipWrapper {
  display: flex;
  padding: 12px 0;
  flex-wrap: wrap;
}

.settingChip {
  height: 24px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.proxyTypeSelect {
  width: 414px !important;
  margin: 0 8px 12px !important;
}

.zoneTableCell {
  border-bottom: 1px solid rgb(243, 243, 243) !important;
}

.zoneDetails {
  display: flex;
  align-items: center;
}

button.zoneDetails__toggleButton {
  margin-left: 18px;
}

.zoneDetailsInfo {
  display: flex;
  margin-right: 24px;
  align-items: baseline;
}
.zoneDetailsInfo___title {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.44);
  padding-right: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.zoneDetailsInfo___value {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.zoneStats {
  display: flex;
  padding-top: 8px;
  padding-bottom: 12px;
}
.zoneStats__left,
.zoneStats__right {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.zoneStats__header {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 6px;
}

.zoneStats__gipsContainer {
  display: flex;
  flex-wrap: wrap;
}
.zoneStats__gip {
  display: flex;
  margin-right: 18px;
  margin-bottom: 4px;
}
.zoneStats__gipTitle {
  color: rgba(0, 0, 0, 0.54);
  min-width: 56px;
  font-weight: 500;
}
.zoneStats__gipClient {
  font-weight: 500;
}

.zoneStats__badGipsList {
  display: flex;
  flex-wrap: wrap;
}

.zoneStats__badGip {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 12px;
  display: flex;
}
.zoneStats__empty {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.33);
  font-weight: 500;
}

.zoneStats__badVip {
  color: rgba(0, 0, 0, 0.87);
  padding-right: 4px;
}

.zoneStats__badVipTimes {
  padding-right: 4px;
  font-size: 11px;
}
.zoneStats__badVipTime {
  padding-right: 12px;
  font-size: 11px;
}

.vipStatus {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: flex;
  align-self: center;
  margin-right: 4px;
  background-color: #00ff32;
}
.vipStatus.BAD {
  background-color: red;
}
.vipStatus.GOOD {
  background-color: #00ff32;
}
.vipStatus.COOLING {
  background-color: orange;
}

.zoneDetails__errorBlock {
  display: flex;
  flex-direction: column;
}
.zoneDetails__error {
  font-weight: 500;
  color: red;
  font-size: 14px;
}

.zoneDetails__errorHeader {
  font-size: 12px;
  color: red;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.clearBadProxies {
  text-transform: capitalize;
  font-weight: 400;
  color: #00aaff;
  margin-left: 24px;
  cursor: pointer;
}
.clearBadProxies:hover {
  text-decoration: underline;
}

@import '~antd/dist/antd.css';

* {
  box-sizing: border-box;
}

html,
body {
  overflow: auto;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
  min-width: 960px;
}

* {
  ::-webkit-scrollbar-track {
    background-color: #d5d5d5;
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar {
    width: 3px;
    background-color: darkred;
    height: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9b9b9b;
    width: 3px;
    height: 3px;
  }
}

a:focus {
  text-decoration: none;
}

.main-section,
#root {
  height: 100%;
}

.ant-layout-header {
  padding: 0 24px;
}

.header .ant-menu-item {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.header .ant-menu-item .anticon {
  font-size: 20px;
}

.loading-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*ANT Table overrides*/
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 14px;
  line-height: 18px;
}

.proxyTable .ant-table-thead > tr > th,
.proxyTable .ant-table-tbody > tr > td {
  padding: 12px 16px;
}

.ant-table-pagination.ant-pagination {
  padding-right: 18px;
}

.client-address {
  strong {
    margin-right: 4px;
  }
}

.formInputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  label {
    height: 20px;
    line-height: 20px;
    margin-bottom: 2px;
  }
}

.action-icon {
  cursor: pointer;
  font-size: 20px;
  &:hover {
    opacity: 0.7;
  }
}

.empty-table-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0 32px;
}

.pageSpinnerWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  min-height: 140px;
}

/*Material*/

.tableContainer {
  max-width: 100%;
  overflow: auto;
}

table.material-table tbody tr td,
table.material-table tr th {
  padding: 8px 0 4px 28px;
  line-height: 16px;
  &.Good {
    color: #01e201;
    text-transform: uppercase;
    font-weight: bold;
  }
  &.Cooling {
    color: #eab308;
    text-transform: uppercase;
    font-weight: bold;
  }
  &.Unavailable {
    color: #ff0757;
    text-transform: uppercase;
    font-weight: bold;
  }
}

table.settings-table.material-table tbody tr td,
table.settings-table.material-table tr th {
  padding: 4px 10px 4px 14px !important;
  line-height: 16px;

  .useragent-checkbox {
    max-width: 24px;
    max-height: 24px;
  }
}

.smallCell {
  padding: 4px 18px !important;
}

.matTableActions {
  display: flex;
  justify-content: flex-end;

  button {
    padding: 2px !important;
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    flex: 0 0 auto;
  }
}

.statusIndicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  background-color: #d3d3d3;
  margin-left: auto;
  margin-right: auto;

  &.online {
    background-color: #00d800;
  }
}

.clients__companyCell > strong {
  display: block;
}
.clients__zoneCfg {
  font-weight: 500;
  min-width: 100px;
}
.clients__zoneCfgSmall {
  display: block;
}

.logo {
  width: 240px;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  font-size: 22px;
}

.ant-layout {
  //height: 100%;
}
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoutButton {
}

h1.page-heading {
  margin: 24px 0;
  font-weight: 400;
  font-size: 32px;

}

.inner-page-layout {
  flex: 0 0 auto;
}

.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 84px;
}

.main-inner-content {
  max-width: 1800px;
  margin-bottom: 12px;
}
